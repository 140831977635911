
import { defineComponent } from '@vue/runtime-core';
import randomString from 'random-crypto-string';

import { collection, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { setToast } from '@/store';
import { getAuth, signOut } from '@firebase/auth';
import { deleteObject, ref, getStorage } from 'firebase/storage'
import { getAppHelpers, getRole } from '../helpers';
import makeCSV, { CSVType } from './makeCSV';
import { FirebaseError } from '@firebase/util';

export default defineComponent({
    data: () => ({
        link: '',
        uid: null as string | null,
        creatingUser: false,
        viewLink: '',
        loaded: {
            token: null,
            userId: null,
            used: null,
        },
        loadingUser: false,
        csvType: 'records' as CSVType,
    }),
    computed: { role: getRole },
    methods: {
        async testRun() {
            await this.createUser('tests');
            signOut(getAuth());
            window.location.href = this.link;
        },
        async createUser(countKey: 'subjects' | 'tests' = 'subjects') {
            this.link = ''; this.uid = null;

            try {
                this.creatingUser = true;
                const subjectsRef = collection(getFirestore(), 'subjects');
                const autoSavesRef = collection(getFirestore(), 'autoSaves');
                const countRef = doc(subjectsRef, 'count');
                const count = await getDoc(countRef).then(snap => ++snap.data()![countKey]);

                const token = await randomString(30);
                const id = this.uid = (countKey === 'tests' ? 't' : '') + (10000 + count).toString() + Math.random().toString().slice(2, 5);
                await Promise.all([
                    new Promise<void>((resolve) => {
                        if (countKey === "subjects")
                            deleteObject(ref(getStorage(), "didNotFill.json"))
                                .then(resolve)
                                .catch((error: FirebaseError) => {
                                    if (error.code === "storage/object-not-found") resolve()
                                    else console.error(error)
                                })
                        else resolve()
                    }),
                    updateDoc(countRef, { [countKey]: count }),
                    setDoc(doc(subjectsRef, id), { records: [] }),
                    setDoc(doc(autoSavesRef, id), { records: [] }),
                    setDoc(doc(collection(getFirestore(), 'tokens'), token), {
                        id,
                        usedAt: null,
                        createdAt: Date.now()
                    })
                ]);

                this.link = `${window.location.origin}/token/${token}`;
            } catch (e) {
                console.error("Error adding document: ", e);
                this.link = 'ERROR';
            } finally {
                this.creatingUser = false;
            }
        },
        async testUser() {
            const token = this.viewLink.split('/').slice(-1)[0];

            this.loadingUser = true;
            const tokenRef = doc(collection(getFirestore(), 'tokens'), token);
            const user = await getDoc(tokenRef).then(snap => snap.data());
            this.loadingUser = false;

            Object.assign(this.loaded, user ? { token, userId: user.id, used: user.usedAt !== null } : { token: false });
        },
        makeCSV() {
            makeCSV(this.csvType);
        },
        async copyLink() {
            const { getInviteMessage } = await getAppHelpers();
            await navigator.clipboard.writeText(getInviteMessage(this.link));
            setToast({ text: 'ההודעה הועתקה בהצלחה', type: 'success' })
        }
    }
});
